.diagonallyttbrtl-enter {
	opacity: 0.1;
	transform: translate(50px, -50px) scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
.diagonallyttbrtl-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.diagonallyttbrtl-exit {
	opacity: 1;
}
.diagonallyttbrtl-exit-active {
	opacity: 0;
	transform: translate(50px, -50px);
	transition: opacity 300ms, transform 300ms;
}

.ltrshort-enter {
	opacity: 0;
	transform: translate(-100px, 0px) scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
.ltrshort-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.ltrshort-exit {
	opacity: 1;
}
.ltrshort-exit-active {
	opacity: 0;
	transform: translate(-100px, 0px);
	transition: opacity 300ms, transform 300ms;
}

.ltrmiddle-enter {
	opacity: 0;
	transform: translate(-200px, 0px) scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
.ltrmiddle-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.ltrmiddle-exit {
	opacity: 1;
}
.ltrmiddle-exit-active {
	opacity: 0;
	transform: translate(-200px, 0px) scale(0.8);
	transition: opacity 300ms, transform 300ms;
}

.ltrmiddleetr-enter {
	opacity: 0;
	transform: translate(-400px, 0px) scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
.ltrmiddleetr-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.ltrmiddleetr-exit {
	opacity: 1;
}
.ltrmiddleetr-exit-active {
	opacity: 0;
	transform: translate(400px, 0px) scale(0.8);
	transition: opacity 300ms, transform 300ms;
}

.ltrshortetr-enter {
	opacity: 0;
	transform: translate(-100px, 0px) scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
.ltrshortetr-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.ltrshortetr-exit {
	opacity: 1;
}
.ltrshortetr-exit-active {
	opacity: 0;
	transform: translate(100px, 0px);
	transition: opacity 300ms, transform 300ms;
}

.rtlshort-enter {
	opacity: 0;
	transform: translate(-100px, 0px) scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
.rtlshort-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.rtlshort-exit {
	opacity: 1;
}
.rtlshort-exit-active {
	opacity: 0;
	transform: translate(-100px, 0px);
	transition: opacity 300ms, transform 300ms;
}

/*.ltrmiddle-enter {
    opacity: 0;
    transform: translate(-200px, 0px) scale(0.9);
    transition: opacity 300ms, transform 300ms;
}
.ltrmiddle-enter-active {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
}
.ltrmiddle-exit {
    opacity: 1;
}
.ltrmiddle-exit-active {
    opacity: 0;
    transform: translate(-200px, 0px) scale(0.8);
    transition: opacity 300ms, transform 300ms;
}*/

.rtlmiddle-enter {
	opacity: 0;
	transform: translate(200px, 0px) scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
.rtlmiddle-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.rtlmiddle-exit {
	opacity: 1;
}
.rtlmiddle-exit-active {
	opacity: 0;
	transform: translate(200px, 0px) scale(0.8);
	transition: opacity 300ms, transform 300ms;
}

.rtllong-enter {
	opacity: 0;
	transform: translate(500px, 0px) scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
.rtllong-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.rtllong-exit {
	opacity: 0;
}
.rtllong-exit-active {
	opacity: 0;
	transform: translate(500px, 0px);
	transition: opacity 300ms, transform 300ms;
}

.ltrlong-enter {
	opacity: 0;
	transform: translate(-400px, 0px) scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
.ltrlong-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.ltrlong-exit {
	opacity: 1;
}
.ltrlong-exit-active {
	opacity: 0;
	transform: translate(-400px, 0px);
	transition: opacity 300ms, transform 300ms;
}

.ltrlongetor-enter {
	opacity: 0;
	transform: translate(-400px, 0px) scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
.ltrlongetor-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.ltrlongetor-exit {
	opacity: 0;
}
.ltrlongetor-exit-active {
	opacity: 0;
	transform: translate(400px, 0px);
	transition: opacity 300ms, transform 300ms;
}

.diagonallyrtl-enter {
	opacity: 0;
	transform: translate(200px, -200px) scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
.diagonallyrtl-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.diagonallyrtl-exit {
	opacity: 0;
}
.diagonallyrtl-exit-active {
	opacity: 0;
	transform: translate(200px, -200px);
	transition: opacity 300ms, transform 300ms;
}

/*.ozike-enter {
	z-index: 2;
	opacity: 0;
    transform: translate(0px, 200px);
    transition: opacity 300ms, transform 300ms;
}
.ozike-enter-active {
	opacity: 0.5;
    transform: matrix(1, 0, 0, 1, 0, 0);
}
.ozike-exit {
    opacity: 0;
}
.ozike-exit-active {
	opacity: 0.3;
    transform: translate(0px, 200px);
    transition: opacity 300ms, transform 300ms;
}*/

/*.diagonallyrtl-enter {
    opacity: 0;
    transform: translate(200px, -200px) scale(0.9);
    transition: opacity 300ms, transform 300ms;
}
.diagonallyrtl-enter-active {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
}*/

.vintageimage-enter {
	opacity: 0;
	transform: translate(10px, -50px) rotate(-10deg);
	transition: opacity 300ms, transform 300ms;
}
.vintageimage-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.vintageimage-exit {
	opacity: 0;
	transform: translate(-10px, -50px) rotate(-10deg);
	transition: opacity 300ms, transform 300ms;
}
.vintageimage-exit-active {
	opacity: 0;
}

.vintageimage2-enter {
	opacity: 0;
	transform: translate(10px, -50px) rotate(-10deg);
	transition: opacity 300ms, transform 300ms;
}
.vintageimage2-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.vintageimage2-exit {
	opacity: 0;
	transform: translate(-10px, -50px) rotate(-10deg);
	transition: opacity 300ms, transform 300ms;
}
.vintageimage2-exit-active {
	opacity: 0;
}

.vintageimage3-enter {
	opacity: 0;
	transform: translate(10px, -50px) rotate(-10deg);
	transition: opacity 300ms, transform 300ms;
	/*transform: translate(0, 200);
    transition: transform 300ms;*/
}
.vintageimage3-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);

	/*   transform: translate(0, 200);
	transform: matrix(0.94, -0.34, 0.34, 0.94, 0, 0);
    transition: transform 300ms;*/
}
.vintageimage3-exit {
	opacity: 0;
	transform: translate(-10px, -50px) rotate(-10deg);
	transition: opacity 300ms, transform 300ms;
	/*transform: translate(0, 200);
    transform: matrix(0.94, -0.34, 0.34, 0.94, 0, 0);
    transition: transform 300ms;*/
}
.vintageimage3-exit-active {
	opacity: 0;
}

.vintageimage4-enter {
	opacity: 0;
	transform: translate(100px, -10px) rotate(-10deg);
	transition: opacity 300ms, transform 300ms;
	/*transform: translate(0, 200);
    transition: transform 300ms;*/
}

.vintageimage4-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);

	/*   transform: translate(0, 200);
	transform: matrix(0.94, -0.34, 0.34, 0.94, 0, 0);
    transition: transform 300ms;*/
}
.vintageimage4-exit {
	opacity: 0;
	transform: translate(100px, 10px) rotate(-10deg);
	transition: opacity 300ms, transform 300ms;
	/*transform: translate(0, 200);
    transform: matrix(0.94, -0.34, 0.34, 0.94, 0, 0);
    transition: transform 300ms;*/
}
.vintageimage4-exit-active {
	opacity: 0;
}

.vintageimage5-enter {
	opacity: 0;
	transform: translate(-50px, -50px) rotate(10deg);
	transition: opacity 300ms, transform 300ms;
	/*transform: translate(0, 200);
    transition: transform 300ms;*/
}
.vintageimage5-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);

	/*   transform: translate(0, 200);
	transform: matrix(0.94, -0.34, 0.34, 0.94, 0, 0);
    transition: transform 300ms;*/
}
.vintageimage5-exit {
	opacity: 0;
	transform: translate(-50px, -50px) rotate(10deg);
	transition: opacity 300ms, transform 300ms;
	/*transform: translate(0, 200);
    transform: matrix(0.94, -0.34, 0.34, 0.94, 0, 0);
    transition: transform 300ms;*/
}
.vintageimage5-exit-active {
	opacity: 0;
}

.rotate-enter {
	opacity: 0;
	transform: rotate(30deg);
	transition: opacity 300ms, transform 300ms;
}
.rotate-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.rotate-exit {
	opacity: 1;
}
.rotate-exit-active {
	opacity: 0;
	transform: matrix(1, -0.05, 0.05, 1, 0, 0);
	transform: rotate(30deg) scale(1);
	transition: opacity 300ms, transform 300ms;
}

.history1description-enter {
	opacity: 0.1;
	transform: translate(200px, 30px) scale(0.7);
	transition: opacity 300ms, transform 300ms;
}
.history1description-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.history1description-exit {
	opacity: 0;
}
.history1description-exit-active {
	opacity: 0;
	transform: translate(200px, 30px) scale(0.7);
	transition: opacity 300ms, transform 300ms;
}

.bttmiddle-enter {
	opacity: 0.1;
	transform: translate(0px, 200px);
	transition: opacity 300ms, transform 300ms;
}
.bttmiddle-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.bttmiddle-exit {
	opacity: 1;
}
.bttmiddle-exit-active {
	opacity: 0;
	transform: translate(0px, 200px);
	transition: opacity 300ms, transform 300ms;
}

.ttbmiddle-enter {
	opacity: 0.1;
	transform: translate(0px, -200px);
	transition: opacity 300ms, transform 300ms;
}
.ttbmiddle-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.ttbmiddle-exit {
	opacity: 1;
}
.ttbmiddle-exit-active {
	opacity: 0;
	transform: translate(0px, -50px);
	transition: opacity 300ms, transform 300ms;
}

.ttbshort-enter {
	opacity: 0.1;
	transform: translate(0px, -50px);
	transition: opacity 300ms, transform 300ms;
}
.ttbshort-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.ttbshort-exit {
	opacity: 0;
}
.ttbshort-exit-active {
	opacity: 0;
	transform: translate(0px, -50px);
	transition: opacity 300ms, transform 300ms;
}

.history-enter {
	opacity: 0;
}

.history-enter-active {
	opacity: 1;
	transition: opacity 10ms;
}

/*
.bttmiddle-enter {
    opacity: 0.1;
    transform: translate(0px, 200px);
    transition: opacity 300ms, transform 300ms;
}
.bttmiddle-enter-active {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
}*/

.ozike-enter {
	opacity: 0;
	transform: translate(0px, 200px);
	transition: opacity 300ms, transform 300ms;
}
.ozike-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.ozike-exit {
	opacity: 0;
	transform: translate(0px, 200px);
	transition: opacity 300ms, transform 300ms;
}
.ozike-exit-active {
	opacity: 0;
}

.livefootercontent-enter {
	opacity: 0;
	transform: translate(0px, 200px) scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
.livefootercontent-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.livefootercontent-exit {
	opacity: 0;
}
.livefootercontent-exit-active {
	opacity: 0;
	transform: translate(0px, 200px) scale(0.9);
	transition: opacity 300ms, transform 300ms;
}

.livedescription-enter {
	opacity: 0;
	transition: opacity 500ms;
}
.livedescription-enter-active {
	opacity: 1;
}
.livedescription-exit {
	opacity: 0;
}
.livedescription-exit-active {
	opacity: 0;
	transition: opacity 300ms;
}

.secondPage-enter {
	opacity: 0;
}

.secondPage-enter-active {
	opacity: 1;
	transition: opacity 10ms;
}

.content-enter {
	opacity: 0;
	transform: translate(200px, 0px) scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
.content-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.content-exit {
	opacity: 0;
}
.content-exit-active {
	opacity: 0;
	transform: translate(-200px, 0px);
	transition: opacity 300ms, transform 300ms;
}

.maintitle-enter {
	opacity: 0;
	transform: translate(-200px, 0px) scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
.maintitle-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.maintitle-exit {
	opacity: 0;
}
.maintitle-exit-active {
	opacity: 0;
	transform: translate(-200px, 0px);

	transition: opacity 300ms, transform 300ms;
}

.bird-enter {
	opacity: 0;
	transform: translate(50px, 50px) rotate(10deg);
	transition: opacity 300ms, transform 300ms;
}
.bird-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.bird-exit {
	opacity: 0;
	transform: translate(50px, 50px) rotate(10deg);
	transition: opacity 300ms, transform 300ms;
}
.bird-exit-active {
	opacity: 0;
}
/*.ozike-enter {
    opacity: 0;
    transform: translate(0px, 200px);
    transition: opacity 300ms, transform 300ms;
}
.ozike-enter-active {
    opacity: 0.3;
    transform: matrix(1, 0, 0, 1, 0, 0);
}
.ozike-exit {
    opacity: 0;
}
.ozike-exit-active {
    opacity: 0.3;
    transform: translate(0px, 200px);
    transition: opacity 300ms, transform 300ms;
}*/

.logo-enter {
	opacity: 1;
	transform: translateY(-100px);
	transition: opacity 300ms, transform 300ms;
}
.logo-enter-active {
	transform: matrix(1, 0, 0, 1, 0, 0);
	opacity: 0.5;
}
.logo-exit {
	opacity: 0;
}
.logo-exit-active {
	opacity: 0;
	transform: translateY(-100px);
	transition: opacity 300ms, transform 300ms;
}

.padding-enter {
	opacity: 1;
	transition: transform 2000ms;
	transform: translateY(260px);
}

.padding-enter-active {
	opacity: 1;
}

.padding-exit {
	opacity: 1;
	transform: translateY(-260px);
	transition: transform 2000ms;
}

.padding-exit-active {
	opacity: 1;
}

.growwidth-enter {
	width: 0;
}

.growwidth-enter-active {
	width: 300;
	transition: width 600ms;
}
.growwidth-exit {
	width: 200;
}
.growwidth-exit-active {
	width: 0;
	transition: width 2000ms;
}

.rtlmenu-enter {
	opacity: 0;
	transform: translate(-200px, 0px) scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
.rtlmenu-enter-active {
	opacity: 1;
	transform: matrix(1, 0, 0, 1, 0, 0);
}
.rtlmenu-exit {
	opacity: 1;
}
.rtlmenu-exit-active {
	opacity: 0;
	transform: translate(200px, 0px) scale(0.8);
	transition: opacity 300ms, transform 300ms;
}
.logoinmenu-enter {
	transform: scale(0.8); /* Enyhén kisebb kezdő skála */
	opacity: 0.5; /* Áttetszőség hozzáadása */
}
.logoinmenu-enter-active {
	transform: translate(0px, 0px) scale(1);
	opacity: 1;
	transition: transform 400ms ease-in-out, opacity 800ms ease-in-out; /* Külön transition idő */
}
.logoinmenu-exit {
	transform: scale(1.2) translate(5px, 20px); /* Enyhébb végső skála és áthelyezés */
	opacity: 1;
}
.logoinmenu-exit-active {
	transform: scale(1);
	opacity: 0; /* Áttetszőség hozzáadása az eltűnéshez */
	transition: transform 400ms ease-in-out, opacity 800ms ease-in-out; /* Külön transition idő */
}

.logospace-enter {
	transform: scale(1);
}
.logospace-enter-active {
	transform: scale(2.715);
	transition: all 600ms ease-in-out;
}
.logospace-exit {
	transform: scale(2.1);
}
.logospace-exit-active {
	transform: scale(1);
	transition: all 400ms ease-in-out;
}

html {
	margin-bottom: 0px;
}
body {
	margin: 0px;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* hivatal */
	background-color: #f0f0eb;
	/* madeel */
	/*background-color: #DDE9E6; */
	overscroll-behavior: 'contain';
	display: 'flex';
	height: '100vh';
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@font-face {
	font-family: 'Cloak';
	src: local('CLOAK'), url(./CLOAK.otf) format('otf');
}
@media screen and (max-height: 770px ) and (min-width: 1280px) {
	body{
		zoom: 75%;
	}
}
